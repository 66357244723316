import cloneDeep from 'lodash.clonedeep';

const defaultState = {
  reports: [],
};

const reports = (state = defaultState, action) => {
  switch (action.type) {
    case 'ADD_REPORT': {
      var updatedReports = cloneDeep(state.reports);

      for (let index = 0; index < action.report.length; index++) {
        const element = action.report[index];
        console.log(element);
        if (!updatedReports.includes(element)) {
          updatedReports.push(element);
        }
      }

      return Object.assign({}, state, {
        reports: updatedReports,
      });
    }
    default:
      return state;
  }
};

export default reports;
