import orientationDropdownEnabledToggle from './orientationDropdownEnabledToggle';
import extensions from './extensions';
import preferences from './preferences';
import servers from './servers';
import studies from './studies';
import timepointManager from './timepointManager';
import viewports from './viewports';
import reports from './reports';

const reducers = {
  extensions,
  orientationDropdownEnabledToggle,
  preferences,
  servers,
  studies,
  timepointManager,
  viewports,
  reports,
};

export default reducers;
