export const defaultState = true;

const orientationDropdownEnabledToggle = (state = defaultState, action) => {
  switch (action.type) {
    case 'TOGGLE_ORIENTATION_DROPDOWN':
      return !state;
    default:
      return state;
  }
};

export default orientationDropdownEnabledToggle;
