import React from 'react';
import PropTypes from 'prop-types';
import { Thumbnail } from './Thumbnail.js';
import { Separator } from './Separator.js';

import './StudyBrowser.styl';

function StudyBrowser(props) {
  const {
    studies,
    onThumbnailClick,
    onThumbnailDoubleClick,
    supportsDrag,
    showThumbnailProgressBar,
  } = props;

  return (
    <div className="study-browser">
      <ul className="scrollable-study-thumbnails">
        {studies
          .map((study, studyIndex) => {
            const { StudyInstanceUID } = study;
            let modifiedThumbnails = study.thumbnails.map(
              (thumb, thumbIndex) => {
                // TODO: Thumb has more props than we care about?
                const {
                  active,
                  altImageText,
                  displaySetInstanceUID,
                  imageId,
                  InstanceNumber,
                  numImageFrames,
                  SeriesDescription,
                  SeriesNumber,
                  hasWarnings,
                } = thumb;

                return (
                  <li
                    key={thumb.displaySetInstanceUID}
                    className="thumbnail-container"
                    data-cy="thumbnail-list"
                  >
                    <Thumbnail
                      active={active}
                      supportsDrag={supportsDrag}
                      key={`${studyIndex}_${thumbIndex}`}
                      id={`${studyIndex}_${thumbIndex}`} // Unused?
                      // Study
                      StudyInstanceUID={StudyInstanceUID} // used by drop
                      // Thumb
                      altImageText={altImageText}
                      imageId={imageId}
                      InstanceNumber={InstanceNumber}
                      displaySetInstanceUID={displaySetInstanceUID} // used by drop
                      numImageFrames={numImageFrames}
                      SeriesDescription={SeriesDescription}
                      SeriesNumber={SeriesNumber}
                      hasWarnings={hasWarnings}
                      // Events
                      onClick={onThumbnailClick.bind(
                        undefined,
                        displaySetInstanceUID
                      )}
                      onDoubleClick={onThumbnailDoubleClick}
                      showProgressBar={showThumbnailProgressBar}
                    />
                  </li>
                );
              }
            );

            studyIndex + 1 !== studies.length
              ? modifiedThumbnails.push(
                  <li
                    key={'separator -' + { studyIndex }}
                    className="thumbnail-container"
                    data-cy="thumbnail-list"
                  >
                    <Separator></Separator>
                  </li>
                )
              : null;

            return modifiedThumbnails;
          })
          .flat()}
      </ul>
    </div>
  );
}

const noop = () => {};

StudyBrowser.propTypes = {
  studies: PropTypes.arrayOf(
    PropTypes.shape({
      StudyInstanceUID: PropTypes.string.isRequired,
      thumbnails: PropTypes.arrayOf(
        PropTypes.shape({
          altImageText: PropTypes.string,
          displaySetInstanceUID: PropTypes.string.isRequired,
          imageId: PropTypes.string,
          InstanceNumber: PropTypes.string,
          numImageFrames: PropTypes.number,
          SeriesDescription: PropTypes.string,
          SeriesNumber: PropTypes.string,
          stackPercentComplete: PropTypes.number,
        })
      ),
    })
  ).isRequired,
  supportsDrag: PropTypes.bool,

  showThumbnailProgressBar: PropTypes.bool,
};

StudyBrowser.defaultProps = {
  studies: [],
  supportsDrag: false,
  onThumbnailClick: noop,
  onThumbnailDoubleClick: noop,
  showThumbnailProgressBar: false,
};

export { StudyBrowser };
